import '../App.css';
import Landing from '../components/Landing';
import Roadmap from '../components/Roadmap';
import Preview from '../components/Preview';
import NftPreview from '../components/NftPreview';
import Tokenomics from '../components/Tokenomics';
import Team from '../components/Team';
import Partners from '../components/Partners';
import Token from '../components/Token';

function LandingPage() {

    return (
        <>
            <Landing />
            <Preview />
            <Token />
            <NftPreview />
            <Tokenomics />
            <Roadmap />
            <Team />
            <Partners />
        </>
    );
};

export default LandingPage;