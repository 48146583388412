import React from 'react'
import { Container, Row, Col } from 'reactstrap';
import '../App.css';

function Team() {
    return (
        <div className="team-container">
            <Container className="py-5 px-md-5 text-center text-md-start">
                <h1>Team</h1>
                <Row className="pt-5 px-md-5">
                    <Col md={3} className=''>
                        <img src="https://firebasestorage.googleapis.com/v0/b/wurklo.appspot.com/o/profilePic.webp?alt=media&token=f0f6e321-e5b7-4825-8c34-c90d39ad800d" alt="wurklo coin" id="coin-img" className="shadow team-img" />
                        <p className='text-center me-4'>Bobby Keel Founder<br/><span className='team-text'>Developer</span></p>
                    </Col>
                    {/* <Col md={3} className=''>
                        <img src="https://firebasestorage.googleapis.com/v0/b/wurklo-web-app.appspot.com/o/wurklo_new_logo_white_blackbg.png?alt=media&token=fdf68760-e902-435e-b71f-c7778f1034a5" alt="wurklo coin" id="coin-img" className="shadow team-img" />
                        <p className='text-center me-4'>This Could Be You. Join the future of Wurk<br/><span className='team-text'>Inquire Now</span></p>
                    </Col> */}
                    {/* <Col md={3} className=''>
                        <img src="https://firebasestorage.googleapis.com/v0/b/wurklo.appspot.com/o/nikkiProfile.jpeg?alt=media&token=6670e4af-758f-4429-9289-df2070550a39" alt="wurklo coin" id="coin-img" className="shadow team-img" />
                        <p className='text-center me-4'>Nikki Rubrico Socials<br/><span className='team-text'>Social/Ads/Users</span></p>
                    </Col> */}
                    {/* <Col md={3} className=''>
                        <img src="https://firebasestorage.googleapis.com/v0/b/wurklo.appspot.com/o/Polish_20220322_113431684.png?alt=media&token=0818dcbc-2d3d-4e32-8934-833dae112ffe" alt="wurklo coin" id="coin-img" className="shadow team-img" />
                        <p className='text-center me-4'>Kirayama Kuoji<br/><span className='team-text'>Designer</span></p>
                    </Col> */}
                </Row>
            </Container>
        </div>
    )
}

export default Team