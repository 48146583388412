import React from "react";
import { Container, Row, Col, Button } from 'reactstrap';

function Wurkloapp() {

    return (
        <Container className="my-5 pt-5">
        <Row>
            <Col>
                <h1 className="text-center mt-4">Wurklo App</h1>
            </Col>
        </Row>
        <Row>
            <Col md={3} className="g-0 text-md-start text-center">
                <img src="https://firebasestorage.googleapis.com/v0/b/wurklo.appspot.com/o/new_app_mobeil_black_blue.png?alt=media&token=95636084-bc75-4952-838b-5f798f133e2b" alt="desktop preview" width="300" />
            </Col>
            <Col className="text-center mx-2 mt-md-1 mt-xl-3 mb-5 text-lg-start ">
                <p>Wurklo connects people to wurkers that provide services with our searchable database of wurkers with messaging.</p>
                <p>Wurklo allows you to wurk with people from around the world, or locally within your neighborhood.</p>
                <p>You can hire, message, save contacts, or create a wurker profile.</p>
                <p>The beauty of our platform is wurkers can create any unique service, or choose from preset professions. This goes nicely with our keyword search which accepts any keywords you choose.</p>
                {/* <h5 className="mb-4">We are different in that we provide this service in its simplest form, we will never harvest or collect your user data, we only collect the data you enter into your profile, and we will never sell ads.</h5>
                <h5 className="mb-4">All of this provided that we are able to be successful with a subscription model where the monthly subscription will be $0.99. For each person who uses our service for 100 years the total cost would be $1188.</h5> */}
                <a href='https://wurklo-web-app.web.app/' target="_blank"><Button className="p-3 ms-4 ms-md-0" id="whitepaper-button">Sign up now</Button></a>
            </Col>
        </Row>
    </Container>
    );
};

export default Wurkloapp;