import React, { useEffect, useState } from 'react';
import { Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, Collapse, NavbarText, Button } from 'reactstrap';
import '../styles/header.css';
import logo from '../images/VectorEPS_ByTailorBrands-white.svg'


function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [showNav, setShowNav] = useState(true);

    const controlNavbar = () => {
        if (window.scrollY>100) {
            setShowNav(false)
        } else {
            setShowNav(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, [])

    return (
        <nav className="fixed-top">
            <Navbar isOpen={isOpen} expand="xl" full dark className='container gx-md-5 px-md-5'>
                <NavbarBrand href="/">
                    <img src={logo} className="navbar-coin" alt="Wurklo logo" />
                    Wurklo
                    <div className="divider d-none d-lg-block"></div>
                </NavbarBrand>
                <NavbarToggler onClick={() => setIsOpen(isOpen ? false : "isOpen")} />
                <Collapse isOpen={isOpen} navbar>
                    <Nav navbar className="justify-content-start" style={{ width: "100%" }}>
                        <NavItem className='pt-1 pt-lg-0'><a onClick={() => setIsOpen(false)} href='https://app.wurklo.com/' target="_blank" rel="noreferrer">App</a></NavItem>
                        <NavItem className='pt-1 pt-lg-0'><a onClick={() => setIsOpen(false)} href='https://wurklo.gitbook.io/wurklo/' target="_blank" rel="noreferrer">GitBook</a></NavItem>
                        <NavItem className='pt-1 pt-lg-0'><a onClick={() => setIsOpen(false)} href='/#timeline'>Roadmap</a></NavItem>
                        <NavItem className='pt-1 pt-lg-0'><a onClick={() => setIsOpen(false)} href='/#tokenomics'>Tokenomics</a></NavItem>
                        <NavItem className='pt-1 pt-lg-0'><a onClick={() => setIsOpen(false)} href='/#contact'>Socials</a></NavItem>
                        <NavItem className='pt-1 pt-lg-0'><a onClick={() => setIsOpen(false)} href='/#contact'>Contact</a></NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </nav>
    );
};

export default Header;