import { Container, Row, Col, Button } from 'reactstrap';
import '../App.css';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import solscanLogo from "../images/solscanLogo.svg"
import logo from '../images/VectorEPS_WurkloCoin.svg'

function Token() {
    return (
        <div className="landing">
            <Container className="px-md-4 text-center text-md-start">
                <Row className="pb-5 ps-md-4 mx-md-1 me-md-3">
                    <Col md={4} className='order-md-2 mt-5 mt-md-0'>
                        <img src={logo} alt="wurklo coin" id="coin-img" className="mx-auto px-5 px-md-0" />
                    </Col>
                    <Col md={8} className='order-md-1 mt-5 mt-md-0'>
                        <h1>WURK</h1>
                        <h2 className='mb-4'>The Wurkers Coin</h2>
                        <Button onClick={() => {
                            navigator.clipboard.writeText('GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV')
                        }} className='contract p-1 border border-2 shadow mb-4'>GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV<ContentCopyIcon className='fs-6 ms-1' /></Button>
                        <br />
                        <a href='https://solscan.io/token/GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV' target="_blank" rel="noreferrer"><img className='solseaRaydium__logos mt-lg-2 mb-5 ms-4 ms-md-0' src={solscanLogo} alt=''></img></a>
                        <br />
                        {/* <Link to="/buynow"><Button className='ms-4 ms-md-0 shadow' id="whitepaper-button">Buy Now</Button></Link> */}
                        <Link to="/coin"><Button className='ms-4 ms-md-0 shadow' id="whitepaper-button">Learn more</Button></Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Token;