import { Container, Row, Col, Button } from 'reactstrap';
import '../App.css';
import { Link } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import solscanLogo from "../images/solscanLogo.svg"
import logo from '../images/VectorEPS_WurkloCoin.svg'

function Landing() {
    return (
        <div className="landing">
            <Container className="mt-5 pt-5 px-md-5 text-center">
                <Row className="pb-5 pt-5 px-md-5">
                    <Col className=''>
                        <h1>Find Wurkers Fast</h1>
                        <h2 className='mb-4'>Search Wurkers, Chat, Hire, Pay</h2>
                    </Col>
                </Row>
                <Row className='mb-5 pb-5'>
                    <Col className='text-center'>
                        <a href='https://app.wurklo.com/' target="_blank" rel="noreferrer"><Button className='landing__signupButton me-md-5 bg-primary text-white' id="whitepaper-button">Sign up to Wurklo</Button></a>
                        {/* <Button className='mx-5 bg-primary text-white' id="whitepaper-button">Buy the WURK token</Button> */}
                        <a href='https://wurklo.gitbook.io/wurklo/' target="_blank" rel="noreferrer" className='mx-auto'><Button id="whitepaper-button">Read about Wurklo</Button></a>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Landing;