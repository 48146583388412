import React from "react";
import { Container, Row, Col } from 'reactstrap';
import YouTube from 'react-youtube';

function Nftabout() {
    const opts1 = {
        height: '300',
        width: '350',
        playerVars: {
            autoplay: 1,
            loop: 1,
            playlist: "2e1g9vKifmk",
        },
    };


    const opts2 = {
        height: '300',
        width: '350',
        playerVars: {
            autoplay: 1,
            loop: 1,
            playlist: "NMl5Nr7nz8M",
        },
    };


    const opts3 = {
        height: '300',
        width: '350',
        playerVars: {
            autoplay: 1,
            loop: 1,
            playlist: "ccWKYzox1qc",
        },
    };
    return (
        <Container className="viewport100 mt-5 pt-md-5 text-center text-lg-start">
            <Row className="pt-5 px-md-5">
                <Col>
                    <h1>WURK NFTs</h1>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col md={10} className="text-center mx-1 text-lg-start">
                    <p>The Wurklo NFT collection includes a series of NFTs representing the tools used in a labor market. Every image is categorized into a series of Bronze, Silver and Gold representing the rarity of the image. </p>
                    <p>Total supply: 9,999</p>
                </Col>
                <Col md={10} className="text-center mx-1 mb-5 text-lg-start">
                    <p className="mb-4">Our WURK NFTs are verified on <a className="me-0" href='https://solsea.io/collection/61e64ac2c4bd70b891f35435' target="_blank" rel="noreferrer">Solsea</a>, <a className="me-0" href='https://nft.raydium.io/marketplace/wurklo' target="_blank" rel="noreferrer">Raydium</a>, <a className="me-0" href='https://magiceden.io/marketplace/wurklo' target="_blank" rel="noreferrer">Magic Eden</a>, and <a className="me-0" href='https://digitaleyes.market/collections/Wurklo' target="_blank" rel="noreferrer">Digital Eyes</a>.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h2 className='text-center pb-5'>Laborer Series</h2>
                </Col>
            </Row>
            <Row className="pb-5">
                <Col xs={12} md={4} className='text-center g-0 px-5 px-md-0'>
                    <YouTube className="founderVideo" opts={opts1} />
                    <p id='launch'>Paintbrush Bronze</p>
                </Col>
                <Col xs={12} md={4} className='text-center g-0 px-5 px-md-0'>
                    <YouTube className="founderVideo" opts={opts2} />
                    <p id='launch'>Paintbrush Silver</p>
                </Col>
                <Col xs={12} md={4} className='text-center g-0 px-5 px-md-0'>
                    <YouTube className="founderVideo" opts={opts3} />
                    <p id='launch'>Paintbrush Gold</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Nftabout;