import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

function Preview() {

    return (
        <div className="preview" id="preview-bg">
            <Container className="py-5 px-4 p-lg-5">
                <Row className="preview__app tex-center text-md-start ms-md-5 mx-auto">
                    <Col className='preview__appLaptop'>
                        <img src="https://firebasestorage.googleapis.com/v0/b/wurklo.appspot.com/o/laptop_wurklo.png?alt=media&token=77cf67e1-608c-4cc4-9615-5605e04da4dd" alt="desktop preview" />
                        <div className='preview__appMobile'>
                            <img src="https://firebasestorage.googleapis.com/v0/b/wurklo.appspot.com/o/new_app_mobeil_black_blue.png?alt=media&token=95636084-bc75-4952-838b-5f798f133e2b" alt="desktop preview" />
                        </div>
                    </Col>
                </Row>
                <Row className='mt-5 pt-5 px-4'>
                    <h1 id="app-preview">Wurklo Ecosystem</h1>
                    <Col md={4} className="preview__feature px-4 pb-3 p-md-5 bg-primary">
                        <h2>Wurklo Platform</h2>  
                        <p className='fs-4'>Search wurkers, chat, hire, & pay for services. Review wurkers with WurkEval&#8482; a performance & character evaluation</p>
                        <Link to="/wurklo"><Button className='' id="whitepaper-button">Learn more</Button></Link>                 
                    </Col>
                    <Col md={4} className="preview__feature my-3 my-md-0 mx-md-3 px-4 pb-3 p-md-5 bg-primary">
                        <h2>WURK Token</h2>
                        <p className='fs-4'>Used for payment processing within the app. Use WurkPay&#8482; to safely pay with protection against incomplete wurk.</p>
                        <Link to="/coin"><Button className='' id="whitepaper-button">Learn more</Button></Link>
                    </Col>
                    <Col className="preview__feature px-4 pb-3 p-md-5 bg-primary">
                        <h2>WURK NFTs</h2>
                        <p className='fs-4'>Wurklo NFTs represent the tools used by wurkers. The first series is Laborer. Perks to be announced soon.</p>
                        <Link to="/nft"><Button className='' id="whitepaper-button">Learn more</Button></Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Preview;