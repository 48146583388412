import React from "react";
import { Container, Row, Col } from 'reactstrap';
import logo from '../images/VectorEPS_WurkloCoin.svg';

function Coin() {

    return (
        <Container className="viewport100 my-5 pt-5">
            <Row className="my-4">
                <Col>
                    <h1 className="text-center mt-4">WURK</h1>
                </Col>
            </Row>
            <Row>
                <Col md={3} className="mb-5 mt-md-5 text-md-start text-center">
                    <img src={logo} alt="desktop preview" width="300" />
                </Col>
                <Col className="text-center mx-1 mb-5 text-lg-start ">
                    <h6 className="mb-0 font-small wurklo__text">Contract Address: GYyL45cnhDWEcr2iEs5sxWND5SLXa7VMFUmwp1uM92YV</h6>
                    <p className="mb-0 wurklo__text">WURK is a utility token. We use the Solana blockchain (our WURK token) for payment within the app as a common exchange between people.</p>

                    <p className="mb-0 wurklo__text">We use this system with fiat to allow for a better system of payment where users can use crypto when this is a better fit, and fiat when that is better fit for the situation.</p>

                    <p className="mb-0 wurklo__text">Additionally, by using Solana we are able to write a smart contract (WurkPay&#8482;) which can hold funds until both parties agree the wurk has been completed. This protects the both parties against situations where the agreement between parties is not met.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Coin;